.paginacao{
	display: flex;
	flex-wrap: wrap;
	padding-top:24px;

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item {
		display: inline-block;
		color: #0a1c39;
		font-size:15px;
		font-family: 'montserratbold';

		& + .page__item{

			&:before{
				content:'|';
				display:inline-block;
				margin:0 4px;
			}

		}

		&[aria-label="Prev"]{
			margin-right:4px;
		}

		&[aria-label="Next"]{
			margin-left:4px;
		}

		&[aria-label="Prev"],
		&[aria-label="Next"]{

			&:before{
				display:none;
			}

		}

	}

	a.page__item:hover{
		color:#06944f;
	}

	span.page__item--active{
		color: #06944f;
	}

	& > *:nth-child(2){

		&.page__item{

			&:before{
				display:none;
			}

		}

	}

}
