.card-quem-somos{
	position:relative;
	border-bottom:1px solid #08934f;
	padding-bottom:11px;
	margin-bottom:32px;

	.card-quem-somos__number{
		font-size:67px;
		font-family: 'montserratextrabold';
		color:#0d2751;
		line-height:0.8;
		margin-left:-4px;
		position:relative;

		&:before{
			content:'';
			display:block;
			font-size:30px;
			width:1em;
			height:1em;
			position:absolute;
			background-color:#CBDCEC;
			border-radius:100%;
			z-index:-2;
			left:-8px;
			top:0;
		}

	}

	.card-quem-somos__absolute{
		text-shadow: 2px 0 0 lighten(#e3e3e3,4%), -2px 0 0 lighten(#e3e3e3,4%), 0 2px 0 lighten(#e3e3e3,4%), 0 -2px 0 lighten(#e3e3e3,4%), 1px 1px lighten(#e3e3e3,4%), -1px -1px 0 lighten(#e3e3e3,4%), 1px -1px 0 lighten(#e3e3e3,4%), -1px 1px 0 lighten(#e3e3e3,4%);
		color:#FFF;
		position:absolute;
		z-index:-2;
		top:0;
		letter-spacing:10px;
		line-height:1;
		right:-114px;
		font-size:145px;
		font-family: 'montserratextrabold';

		@include media-breakpoint-down(md){
			font-size:100px;
			right:-40px;
		}

	}

	.card-quem-somos__title{
		color:lighten(#08733f,2%);
		font-size:16px;
		font-family: 'montserratbold';
	}

}

.card-servicos{
	background-color:#FFF;
	text-align:center;
	max-width:350px;
	text-align:center;
	margin-left:auto;
	margin-right:auto;
	border-bottom:4px solid #00944A;
	position:relative;
	margin-bottom:42px;
	transition:border-bottom-color 0.6s linear;
	min-height:140px;

	&:before{

		@include media-breakpoint-up(lg){
			content:'';
			display:block;
			width:100%;
			height:54px;
			background-color:#131929;
			background-image:url('../images/bg-servicos-barras.jpg');
			position:absolute;
			top:0;
			left:0;
		}

	}

	.card-servicos__figure{
		max-width:108px;
		width:100%;
		margin-left:auto;
		margin-right:auto;
		min-height:108px;
		background-color:#00944A;
		border-radius:100%;
		display:flex;
		align-items:center;
		position:relative;
		z-index:2;
		justify-content:center;
		transition:background-color 0.6s linear;
	}

	.card-servicos__content{
		padding-left:25px;
		padding-right:25px;
		padding-bottom:20px;

		.card-servicos__title{
			color:#08934f;
			font-size:13px;
			font-family: 'montserratextrabold';
			margin-bottom:8px;
			transition:color 0.6s linear;
		}

		.card-servicos__desc{
			font-size:13px;
			max-width:225px;
			margin-left:auto;
			margin-right:auto;
			line-height:1.2;
			margin-bottom:6px;

			transition:all 0.6s linear;
			min-height:102px;
		}

		.card-servicos__footer{

			@include media-breakpoint-up(lg){
				opacity:0;
			}

		}

	}

	&:hover{
		border-bottom-color:#0d2751;

		.card-servicos__figure{
			background-color:#0d2751;
		}

		.card-servicos__content{

			.card-servicos__title{
				color:#0d2751;
			}

			.card-servicos__desc{

			}

			.card-servicos__footer{

				@include media-breakpoint-up(lg){
					opacity:1;
					animation:fadeInUp 0.6s forwards linear;
				}

			}

		}

	}

}

.card-pdf{
	text-align:center;
	min-width:258px;
	box-shadow:0 0 8px rgba(#000,0.2);
	padding:20px 15px;
	border:2px solid transparent;
	transition:all 0.6s linear;
	margin-bottom:24px;

	.card-pdf__icon{
		margin-bottom:12px;
	}

	.card-pdf__title{
		font-size:13px;
		font-family: 'montserratextrabold';
		margin-bottom:8px;
		color:#08934f;
		transition:color 0.6s linear;
		line-height:1.2;
	}

	.card-pdf__desc{
		line-height:1.2;
		color:#1a2c48;
		margin-bottom:12px;
	}

	&:hover{
		border-color:#012653;
		box-shadow:none;

		.icon-pdf{
			@extend .icon-pdf-blue;
		}

		.card-pdf__title{
			color:#012653;
		}

	}

}

.card-cursos{
	text-align:center;
	margin-bottom:30px;

	.card-cursos__figure{
		min-height:70px;
		width:100%;
		max-width:70px;
		margin-left:auto;
		margin-right:auto;
		background-color:#00944A;
		transition:background-color 0.6s linear;
		display:flex;
		align-items:center;
		border-radius:100%;
		justify-content:center;
		margin-bottom:12px;
	}

	.card-cursos__title{
		font-size:13px;
		font-family: 'montserratextrabold';
		color:#08934f;
		transition:color 0.6s linear;
		margin-bottom:7px;
	}

	.card-cursos__desc{
		line-height:1.1;

		// &:after{
		// 	content:'';
		// 	display:block;
		// 	margin-left:auto;
		// 	margin-right:auto;
		// 	font-size:7px;
		// 	width:1em;
		// 	height:1em;
		// 	margin-top:12px;
		// 	border-radius:100%;
		// 	transition:background-color 0.6s linear;
		// 	background-color:#08934f;
		// }

	}

	&:hover{

		.card-cursos__figure{
			background-color:#012756;
		}

		.card-cursos__title{
			color:#012756;
		}

		.card-cursos__desc{

			&:after{
				background-color:#012756;
			}

		}

	}

}

.card-depoimentos{
	text-align:right;
	position:relative;
	padding-top:24px;
	padding-left:5px;
	margin-bottom:40px;

	&:before{
		content:'';
		display:block;
		width:84px;
		height:66px;
		background-image:url('../images/bg-depoimentos.png');
		background-repeat:no-repeat;
		opacity:0.55;
		position:absolute;
		left:5px;
		top:0;
		z-index:-2;
	}

	.card-depoimentos__title{
		font-size:18px;
		font-family: 'montserratsemibold';
		margin-bottom:16px;
		min-height:54px;
	}

	.card-depoimentos__desc{
		line-height:1.2;
		font-family: 'montserratlight';
	}

}

.card-dessa-social{
	margin-bottom:32px;

	.card-dessa-social__figure{
		margin-bottom:12px;
		position:relative;
		max-width:346px;
		margin-left:auto;
		margin-right:auto;

		// &:before{
		// 	content:'';
		// 	display:block;
		// 	position:absolute;
		// 	top:0;
		// 	left:0;
		// 	width:100%;
		// 	height:100%;
		// 	background-color:rgba(#08934f,0.75);
		// 	z-index:5;
		// 	opacity:0;
		// 	transform:scale(0);
		// 	transition:opacity 0.6s, transform 0.6s linear;

		// }

	}

	.card-dessa-social__content{
		font-size:13px;

		.card-dessa-social__title{
			color:#08934f;
			font-family: 'montserratextrabold';
			margin-bottom:6px;
		}

		.card-dessa-social__desc{
			line-height:1.1;
		}

	}

	&:hover{

		.card-dessa-social__figure{

			&:before{
				opacity:1;
				transform:scale(1);
			}

		}

	}

}
