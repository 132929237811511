@import "_fontfaces";

html{
	scroll-behavior:smooth;
}

body{
	font-family: 'montserratregular';
	color:#0a1c39;
	font-size:13px;
}

#app{
	position:relative;
	overflow:hidden;
}

.line--clamp-4{
	@include line-clamp(4)
}

.slick-slider{
	text-align:center;

	.slick-track{
		
		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

picture{
	display:inline-block;
}

.font--italic{
	font-style:italic;
}

.line--height-1-1{
	line-height:1.1;
}

.line--height-1-2{
	line-height:1.2;
}

.text--white{
	color:#FFF !important;
}

.text--08733f{
	color:#08733f !important;
}

.text--08934f{
	color:#08934f !important;
}

.text--118035{
	color:#118035 !important;
}

.embed-responsive-1920by374{
	max-height:374px;
	overflow:hidden;

	&:before{

	}

}

.font--fnt-15{
	font-size:15px !important;
}

.font--fnt-18{
	font-size:18px !important;
}

.font--fnt-30{
	font-size:30px !important;
}

.font--mnt-thin{
	font-family: 'montserratthin' !important;
}

.font--mnt-bld{
	font-family: 'montserratbold' !important;
}

.font--mnt-lgt{
	font-family: 'montserratlight' !important;
}

.font--mnt-ex-bld{
	font-family: 'montserratextrabold' !important;
}

.font--mnt-reg{
	font-family: 'montserratregular' !important;
}

.font--mnt-semi-bld{
	font-family: 'montserratsemibold' !important;
}

.font--roboto-itc{
	font-family: 'roboto-italic' !important;
}

.font--worksans-reg{
	font-family: 'work_sansregular' !important;
}

.letter--spacing-4{
	letter-spacing:4px;
}

.media-custom{

	@include media-breakpoint-up(sm){
		display:flex;
		align-items:flex-end;
	}

	.media-custom__icon{
		margin-bottom:10px;

		@include media-breakpoint-up(sm){
			margin-right:4px;
			margin-bottom:0;
		}

	}

	.media-custom__text{

		span{
			font-family: 'montserratbold';
		}

	}

}

.segura-carousel{
	position:relative;

	.slick-slider{
		z-index:30;
	}

	.segura-carousel__controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		padding-bottom:19px;

		.clearfix{
			width:100%;

			& > *{
				font-size:10px;
				width:(38 / 10) * 1em;
				height:(38 / 10) * 1em;
				line-height:(38 / 10) * 1em;
				display:inline-flex;
				align-items:center;
				justify-content:center;
				text-align:center;
				background-color:#00944A;
				color:#FFF;
				z-index:400;
				border-radius:100%;
				position:relative;

				&.float-left{
					left:-8px;
				}

				&.float-right{
					right:-8px;
				}

				@include hover-focus{
					color:#FFF;
					background-color:#00623B;
				}

			}

		}

	}

}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

[data-desligador="true"]{
	display:none;
	background-color:rgba(#000000,0.6);
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:700;
	cursor:pointer;
}

@keyframes explodeMidia{

	0%{
		opacity:1;
		transform:scale(1);
	}

	100%{
		opacity:0;
		transform:scale(18);
	}

}

@keyframes explodeMidiaMenor{

	0%{
		opacity:1;
		transform:scale(1);
	}

	100%{
		opacity:0;
		transform:scale(9);
	}

}
body{
	transition:padding-left 0.6s linear;

	&.menu-active{

		.btn--responsivo{

			.bars{
				transform:rotate(45deg);

				@include beforeAfter{
					transform:rotate(-90deg);					
				}

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

			}

		}

		.topo{

			.nav-content{
				left:0;
			}

		}

	}


}