// bg-position(x,y) width height
$icons: (
	'icon-aviao': 0px -52px 18px 17px,
	'icon-cartao': -28px -52px 21px 14px,
	'icon-mail': -100px -27px 16px 16px,
	'icon-pdf-blue': 0px 0px 40px 42px,
	'icon-pdf-menor': -100px 0px 16px 17px,
	'icon-pdf': -50px 0px 40px 42px,
	'icon-tel': -100px -53px 16px 16px,
	'zap': 0px -79px 16px 16px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 116px 95px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 116px 95px;

	display: inline-block;
	vertical-align: middle;

}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
