.rodape{

	.rodape__btn-whatsapp{
		position:fixed;
		bottom:15px;
		left:15px;
		z-index:400;
		background-color:#4caf50;
		border-radius:100%;
		color:#FFF;
		font-size:40px;
		width:(60 / 40) * 1em;
		height:(60 / 40) * 1em;
		line-height:(60 / 40) * 1em;
		@extend .fab;
		@extend .fa-whatsapp;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		box-shadow:0 0 8px rgba(#000,0.5);

		&:after{
			content:'';
			display:block;
			position:absolute;
			top:calc(50% - 7px);
			left:calc(50% - 7px);
			border-radius:100%;
			z-index:-2;
			width:15px;
			height:15px;
			background-color:#4caf50;
			animation:explodeMidiaMenor 4s infinite;
		}

	}

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.rodape__principal{
		padding-top:50px;
		background-color:#071224;
		color:#FFF;
		padding-bottom:50px;

		.container > .row > *{

			@include media-breakpoint-down(md){
				margin-bottom:27px;
			}

		}

		.rodape-principal__logo{
			margin-bottom:24px;
		}

		.rodape-principal__title{
			font-size:15px;
			font-family: 'montserratbold';
			color:#08733f;
			line-height:1;
			margin-bottom:15px;
		}

		.rodape-principal__menu{
			list-style-type:none;
			margin-bottom:15px;
			padding-left:0;
			font-size:14px;
			font-family: 'montserratbold';

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
			}

			& > li{
				line-height:1.3;
				margin-bottom:12px;

				@include media-breakpoint-up(lg){
					width:50%;
					margin-bottom:0;
				}

				&.active > a,
				&:hover  > a{
					color:#08733f;
				}

			}

		}

		.rodape-principal__item{
			font-size:14px;
			line-height:1;
			margin-bottom:16px;
			font-family: 'montserratlight';
			position:relative;

			@include media-breakpoint-up(lg){
				padding-left:16px;
			}

			&:before{
				content:'';
				display:inline-block;
				font-size:8px;
				width:1em;
				height:1em;
				background-color:#FFF;
				border-radius:100%;
				margin-right:4px;

				@include media-breakpoint-up(lg){
					position:absolute;
					left:0;
					top:2px;
					margin-right:0;
				}

			}

		}

	}

	.rodape__copyright{
		background-color:#0d2751;
		color:#FFF;
		font-size:10px;
		padding-top:30px;
		padding-bottom:8px;
		font-family: 'montserratlight';

		.rodape-copyright__gv8{
			font-family: 'roboto-italic';
			font-weight:100;

			img{
				max-width:50px;
			}

		}

		.row{

			& > *{
				margin-bottom:20px;
			}

		}

	}

}
.msg__cookies{
	background: #FFF;
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	padding: 15px;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(#000,.8);
	width: 100%;
	z-index: 999999999;
	border: 1px solid rgba(#000,.2);

  &.d-none{
    display: none;
  }

	a{
		color: #008850;
		text-decoration: underline;
	}

	.btn{
		background-color: #008850;
		font-weight: bold;
    color: #fff;

    &:hover{
      background-color: darken(#008850, 10%);
    }
	}

	@media (max-width: 767px){
		max-width: calc(100% - 30px);
		font-size: 12px;
		text-align: center;

		a{
			display: inline-block;
		}

		.content{
			margin-bottom: 10px;
		}
	}

  @media (min-width: 767px){
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-column-gap: 10px;
		align-items: center;

		.btn{
			white-space: nowrap;
		}
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		max-width: 768px;
	}

  @media (min-width: 992px) and (max-width: 1199.98px) {
		max-width: 1024px;
	}

  @media (min-width: 1200px) {
		max-width: 1280px;
	}
}
