.topo{

	@include media-breakpoint-up(lg){
		position:absolute;
		top:0;
		color:#FFF;
		left:0;
		z-index:1000;
		padding-top:12px;
		width:100%;
	}

	.bar-topo{
		border-bottom:1px solid #4d5058;
		padding-bottom:5px;

		@include media-breakpoint-down(md){
			text-align:center;
			padding-top:4px;
		}

		.container{

			&{

				@include media-breakpoint-up(lg){
					display:flex;
					align-items:center;
					justify-content:space-between;
				}

			}

			& > *{
				margin-bottom:8px;
				line-height:1;
			}

		}

		.bar-topo__mensagem{
			font-size:13px;
			display: flex;
			align-items: center;

			@include media-breakpoint-up(lg){
				padding-top:2px;
			}

			span{
				font-family: 'montserratbold';
				margin-left: 5px;
			}
			a{
				font-size: 20px;
				line-height: 20px;
				margin-left: 10px;
			}

		}

		.bar-topo__medias{
			.icon__mask{
				background-color: #000;
				margin-right: 5px;
			}

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				.icon__mask{
					background-color: #fff;
					margin-right: 5px;
				}
			}

			& > *{
				@include margin(0,6)
			}

		}

	}

	.nav-content{

		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
			padding-top:12px;
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			align-items:center;
		}

		@include media-breakpoint-down(md){
			position:fixed;
			left:-230px;
			width:230px;
			top:0;
			height:100%;
			border-right:1px solid $theme;
			background-color:#FFF;
			z-index:1000;
			overflow:auto;
			transition:left 0.6s linear;
		}

		.nav-content__logo{
			margin-bottom:0;
			margin-bottom:17px;

			@include media-breakpoint-down(md){
				padding:30px 15px;
				text-align:center;
				margin-bottom:15px;
			}

		}

		.menu-topo{
			list-style-type:none;
			padding-left:0;
			margin-bottom:0;
			font-size:14px;
			font-family: 'Montserrat', sans-serif;
			font-weight: 600;

			@include media-breakpoint-up(lg){
				display:flex;
				align-items:center;
				margin-left:120px;
				justify-content:space-between;
				flex-grow:1;
				padding-bottom:21px;
			}

			& > li{

				& > a{

					@include media-breakpoint-down(md){
						padding:12px 15px;
						display:block;
						border-top:1px solid transparent;
						border-bottom:1px solid transparent;
					}

				}

				&.active > a,
				&:hover  > a{

					@include media-breakpoint-up(lg){
						color:$theme2;
					}

					@include media-breakpoint-down(md){
						background-color:$theme;
						color:#FFF;
						border-color:darken($theme,10%)
					}

				}

				&.dropdown{

					@include media-breakpoint-down(md){
						padding:10px 15px;
					}

					& > *{

						&:after{
							content:"\f078";
							font-size:12px;
							display:inline-block;
							@extend .fas;
							margin-left:4px;
						}

					}

					.dropdown-menu{
						float:none;
						color:#282828;
						font-size:14px;
						font-weight:100;
						font-family: 'Montserrat', sans-serif;
						font-weight: 600;
						border-radius:0;
						border:none;

						@include media-breakpoint-down(md){
							padding-bottom:0;
							position:relative;
						}

						@include media-breakpoint-up(lg){
							padding-left:36px;
							padding-right:35px;
						}

						li{

							a{
								display:block;
								border-bottom:1px solid #dbdbdb;
								padding:10px 15px;

								@include media-breakpoint-down(md){

									&:before{
										content:'-';
										display:inline-block;
										margin-right:6px;
									}

								}

								@include media-breakpoint-up(lg){
									padding:4px 15px 3px 15px;
								}

							}

							&.active > a,
							&:hover  > a{
								color:$theme2;
							}

							&:last-child{

								a{
									border-bottom:none;
								}

							}

						}

					}

					&:hover{

						&:before{

							@include media-breakpoint-up(lg){
								content:'';
								display:block;
								border:6px solid transparent;
								border-bottom-color:#FFF;
								position:absolute;
								top:calc(100% - 1px);
								left:50%;
								transform:translateX(-50%);
							}

						}

						.dropdown-menu{

							@include media-breakpoint-up(lg){
								display:block !important;
								min-width:203px;
								top:calc(100% + 10px);
								text-align:center;
								transform:translateX(-50%);
								left:50%;
								margin:0;
								padding-top:10px;
								padding-bottom:10px;

								&:after{
									content:'';
									display:block;
									position:absolute;
									bottom:100%;
									left:0;
									background-color:transparent;
									width:100%;
									height:10px;
								}

							}

						}

					}

					@include media-breakpoint-down(md){

						.dropdown-menu{
							display:block !important;
						}

					}

				}

			}

		}

	}

	&.topo--internas{
		background-color:#071224;

		.bar-topo{

			.bar-topo__medias{
				.icon__mask{
					background-color: #fff;
				}
			}

			@include media-breakpoint-down(md){
				color:#FFF;
			}

		}

		@include media-breakpoint-up(lg){
			position:relative;
		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			z-index:500;
			background-color:#FFF;
			box-shadow:0 0 8px rgba(#000,0.5);
			animation:fadeInDown 0.6s linear;
		}

	}

}
