.redes-sociais{

	& > *{
		font-size:13px;
		width:(40 / 13) * 1em;
		height:(40 / 13) * 1em;
		line-height:(40 / 13) * 1em;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		background-color:#FFF;
		color:#000;
		border-radius:100%;
		@include margin(0,3);

		@include hover-focus{
			background-color:#00944A;
			color:#FFF;
		}

	}

}