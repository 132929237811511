@mixin place($color:red){
  color:$color;
  @include placeholdIt(){
    color:$color;
  };

  &:focus{
  	color:$color;
  }
  
}