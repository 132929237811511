@import "_home.scss";

.internas{
	padding-bottom:58px;

	&.internas--cursos{
		padding-bottom:42px;
	}

	&.internas--depoimentos{
		padding-bottom:40px;
	}

	.modal.modal--depoimento{
		background-color:rgba(#01143F,0.8);

		.modal-content{
			border-radius:30px;

			@include media-breakpoint-up(md){
				min-width:538px;
			}

		}

		.modal-max-width{
			max-width:428px;
			margin-left:auto;
			margin-right:auto;
			width:100%;
			padding:22px 0 45px 0;
			text-align:center;

			.btn--close{
				font-size:12px;
				border-radius:100%;
				min-width:35px;
				min-height:35px;
				padding:0;
				display:inline-flex;
				align-items:center;
				justify-content:center;
				font-family: 'montserratbold';
				margin-bottom:22px;
			}

		}

	}

	.internas__hr{
		border-top:1px solid #d1d1d1;
	}

	.internas__title,
	.internas__subtitle{
		line-height:1;
		text-align:center;
		font-weight:100;
	}

	.internas__title{
		font-size:30px;
		color:lighten(#08733f,3%);
		font-family: 'montserratlight';
	}

	.internas__subtitle{
		font-size:20px;
		color:lighten(#06944f,2%);
		font-family: 'montserratsemibold';
	}

	.internas__breadcrumb{
		list-style-type:none;
		padding-left:0;
		margin-bottom:42px;
		font-size:13px;
		font-family: 'montserratlight';
		background-color:#019549;
		color:#FFF;
		position:relative;
		padding:5px 0;
		font-style:italic;

		@include beforeAfter{
			content:'';
			display:block;
			width:100vw;
			height:100%;
			background-color:inherit;
			position:absolute;
			top:0;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

		span{

			& + span{

				&:before{
					content:'|';
					display:inline-block;
					margin:0 4px;
				}

			}

		}

	}

	.tab-custom{
		margin-bottom:12px;
		border-radius:30px;
		overflow:hidden;

		& > *{
			width:100%;
		}

		& > .btn{
			color:#0a1c39;
			background-color:#E5E5E5;
			font-size:13px;
			font-family: 'montserratbold';
			display:flex;
			box-shadow:none;
			align-items:center;
			justify-content:space-between;
			border-radius:30px;
			padding:6px 7px 6px 32px;

			.btn{
				padding:0;
				font-size:9px;
				min-width:(31 / 9) * 1em;
				min-height:(31 / 9) * 1em;
				line-height: (31 / 9) * 1em;
				text-align:center;
				display:inline-flex;
				align-items:center;
				justify-content:center;
				background-color:#002859;
				color:#FFF;
				border-radius:100%;
			}

			&[aria-expanded="true"]{
				border-radius:30px 30px 0 0;

				.fas{
					@extend .fa-minus;
				}

				& ~ ul,
				&,
				& ~ ol{
					background-color:#002859;
					color:#FFF;
				}

				.btn{
					background-color:#FFF;
					color:#002859;
				}

			}

		}

		ul,
		ol{
			list-style-type:none;
			padding-left:0;
			margin-bottom:0;
			padding:0 22px 22px;

			&:before{
				content:'';
				width:100%;
				display:block;
				margin-bottom:14px;
				height:1px;
				background-color:#FFF;
			}

		}

		&.tab-custom--green{

			& > .btn{
				color:#0a1c39;
				background-color:#E5E5E5;

				.btn{
					background-color:#019549;
					color:#FFF;
				}

				&[aria-expanded="true"]{

					& ~ ul,
					&,
					& ~ ol{
						background-color:#019549;
						color:#FFF;
					}

					.btn{
						background-color:#FFF;
						color:#019549;
					}

				}

			}

		}

	}

	&.internas--cursos{

		.segura-carousel:not(.segura-carousel-diferente){

			.slick-slider{

				.slick-slide{
					padding-top:10px;
					padding-bottom:10px;
				}

				.internas__figure{
					padding:10px 0;
					max-width:150px;
					margin-left:auto;
					margin-right:auto;
					box-shadow:0 0 4px rgba(#000,0.5);
				}

			}

		}

	}

}