.header-section{
	font-size:30px;
	margin-bottom:12px;

	& > *{
		line-height:1;
		margin-bottom:0;
	}

	.header-section__title{
		font-size:(15 / 30) * 1em;
		color:#202020;
		font-family: 'montserratextrabold';

		&:before{
			content:'';
			display:inline-block;
			margin-right:9px;
			vertical-align:middle;
			width:47px;
			height:3px;
			background-color:currentColor;
		}

	}

	.header-section__subtitle{
		color:lighten(#08733f,2%);
		font-size:1em;
		font-family: 'montserratlight';
		padding-top:12px;
	}

}

.banner{
	max-width:1920px;
	margin-left:auto;
	margin-right:auto;
	text-align:center;

	.carousel-item{
		margin-bottom:0;
	}

	.carousel-indicators{
		margin-left:auto;
		margin-right:auto;
		@include make-container();
		@include make-container-max-widths();
		justify-content:flex-start;
		margin-bottom:53px;

		li{
			width:9px;
			height:9px;
			border-radius:100%;
			border:none;
			@include margin(0,4);
			opacity:1;
			position:relative;
			display:inline-block;
			transition:margin-right 0.6s linear;

			@include beforeAfter{
				content:'';
			}

			&:before{
				display:block;
				position:absolute;
				left:calc(100% + 10px);
				top:50%;
				transform:translateY(-50%);
				width:0;
				height:2px;
				position:relative;
				transition:width 0.6s linear;
				background-color:#8f93a2;
			}

			&:after{
				display:block;
				position:absolute;
				top:0;
				left:0;
				width:100%;
				background-color:transparent;
				height:100%;
				transform:scale(0);
				border-radius:100%;
				box-shadow:0 0 0 1px #FFF;
				transition:transform 0.6s linear;
			}

			&.active{
				margin-right:79px;

				&:before{
					width:60px;
				}

				&:after{
					transform:scale(1.6)
				}

			}

		}

	}

}

.quem-somos{
	padding-top:50px;
	padding-bottom:10px;
	position:relative;

	&:before{
		content:'';
		display:block;
		width:100%;
		position:absolute;
		left:0;
		bottom:0;
		background-color:#071224;
		min-height:240px;
		background-image:url('../images/bg-servicos-barras.jpg');

		@include media-breakpoint-down(md){
			display:none;
		}

	}

	& > .container{

		& > .row{

			& > *{
				margin-bottom:0;

				@include media-breakpoint-down(md){
					margin-bottom:36px;
				}


				&.col-lg-5{

					.text--white{

						@include media-breakpoint-down(md){
							color:#071224 !important;
						}

					}

				}

			}

		}

		.sobre-nos__segura-cards{

			& > *{

				&:last-child{
					margin-bottom:63px;
				}

			}

		}

	}

	.quem-somos__figure{
		margin-bottom:0;
	}

	.sobre-nos__segura-cards{
		position:relative;

		&:before{

			@include media-breakpoint-up(lg){
				content:'';
				display:block;
				width:374px;
				height:464px;
				position:absolute;
				right:-90px;
				opacity:0.3;
				top:0;
				z-index:-2;
				background-image:url('../images/bg-index-letras.png');
			}

		}

	}

}

.servicos{
	padding-top:4px;
	position:relative;

	@include media-breakpoint-down(md){
		background-color:#071224;
	}

	&:before{

		@include media-breakpoint-up(lg){
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:190px;
			background-color:#071224;
			background-image:url('../images/bg-servicos-barras.jpg');
		}

	}

}

.bg-depoimentos{
	padding-bottom:70px;
	position:relative;
	max-width:1920px;
	margin-right:auto;
	margin-left:auto;

	.card-depoimentos{

		.card-depoimentos__title{

			@include media-breakpoint-up(lg){
				min-height:54px;
				max-height:54px;
			}

		}
		
		.card-depoimentos__desc{

			@include media-breakpoint-up(lg){
				max-height:224px;
				min-height:224px;
				overflow:auto;
				padding:10px;

				/* width */
				&::-webkit-scrollbar {
					width: 10px;
				}

				/* Track */
				&::-webkit-scrollbar-track {
					background: darken(#00944A,10%);
				}

				/* Handle */
				&::-webkit-scrollbar-thumb {
					background: #FFF;
				}

				/* Handle on hover */
				&::-webkit-scrollbar-thumb:hover {
					background: darken(#FFF,10%);
				}

			}

		}

	}

	@include beforeAfter{
		content:'';
		display:block;
		position:absolute;
		z-index:-2;
	}

	&:before{
		left:0;
		bottom:0;
		// width:(819 / 1920) * 100%;
		width:100vw;
		height:138px;
		// max-width:819px;
		background-color:#071224;
	}

	&:after{
		right:0;
		top:0;
		background-image:var(--bg);
		width:(1101 / 1920) * 100%;
		background-size:cover;
		min-height:636px;
		max-width:1101px;
	}

	.bg-depoimentos__box{
		max-width:695px;
		width:100%;
		margin-right:auto;
		background-color:#00944A;
		color:#FFF;
		min-height:366px;
		padding-top:72px;
		padding-bottom:44px;
		padding-right:116px;
		padding-left:128px;
		position:relative;
		z-index:0;

		.bg-depoimentos__title{
			display:block;
			font-size:135px;
			position:absolute;
			top:30px;
			text-align:right;
			line-height:0.8;
			right:41px;
			font-family: 'montserratextrabold';
			width:100%;
			height:100%;
			z-index:-1;
			color:#00944A;
			text-shadow:2px 0 0 darken(#00944A,1%), -2px 0 0 darken(#00944A,1%), 0 2px 0 darken(#00944A,1%), 0 -2px 0 darken(#00944A,1%), 1px 1px darken(#00944A,1%), -1px -1px 0 darken(#00944A,1%), 1px -1px 0 darken(#00944A,1%), -1px 1px 0 darken(#00944A,1%);


			@include media-breakpoint-down(md){
				display:none;
			}

		}

		@include media-breakpoint-down(md){
			margin-left:auto;
			padding-left:15px;
			padding-right:15px;
		}

		.card-depoimentos{
			max-width:354px;
		}

		.carousel-indicators{
			display:flex;
			flex-direction:column;
			align-items:flex-end;
			text-align:center;
			position:relative;
			padding-bottom:10px;

			li{
				width:8px;
				height:8px;
				border-radius:100%;
				border:none;
				opacity:1;
				position:relative;
				display:inline-block;
				transition:margin-bottom 0.6s linear;
				margin-bottom:12px;

				@include beforeAfter{
					content:'';
				}

				&:before{
					display:block;
					position:absolute;
					left:calc(50% - 1px);
					top:calc(100% + 10px);
					width:1px;
					height:0px;
					position:relative;
					transition:height 0.6s linear;
					background-color:#FFF;
					z-index:2;
				}

				&:after{
					display:block;
					position:absolute;
					top:0;
					left:0;
					width:100%;
					background-color:transparent;
					height:100%;
					transform:scale(0);
					border-radius:100%;
					box-shadow:0 0 0 1px #FFF;
					transition:transform 0.6s linear;
				}

				&.active{
					margin-bottom:79px;

					&:before{
						height:60px;
					}

					&:after{
						transform:scale(1.7)
					}

				}

			}

		}

	}

}
