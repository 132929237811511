@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratextrabold';
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratlight';
    src: url('#{$fonts}/montserrat_light/montserrat-light-webfont.eot');
    src: url('#{$fonts}/montserrat_light/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratthin';
    src: url('#{$fonts}/montserrat-thin/montserrat-thin-webfont.eot');
    src: url('#{$fonts}/montserrat-thin/montserrat-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-thin/montserrat-thin-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-thin/montserrat-thin-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-thin/montserrat-thin-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-thin/montserrat-thin-webfont.svg#montserratthin') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratregular';
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratsemibold';
    font-display: swap;
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot');
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
		font-family: 'roboto-italic';
		src: url('../fonts/roboto-italic/roboto-italic.eot');
		src: url('../fonts/roboto-italic/roboto-italic.eot?#iefix') format('embedded-opentype'),
		 	 url('../fonts/roboto-italic/roboto-italic.woff2') format('woff2'),
 		 	 url('../fonts/roboto-italic/roboto-italic.woff') format('woff'),
		 	 url('../fonts/roboto-italic/roboto-italic.ttf') format('truetype'),
		 	 url('../fonts/roboto-italic/roboto-italic.svg#roboto-italic') format('svg');
		font-weight: normal;
		font-style: normal;
}

@font-face {
    font-family: 'work_sansregular';
    src: url('#{$fonts}/WorkSans-Regular/worksans-regular-webfont.eot');
    src: url('#{$fonts}/WorkSans-Regular/worksans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/WorkSans-Regular/worksans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/WorkSans-Regular/worksans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/WorkSans-Regular/worksans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/WorkSans-Regular/worksans-regular-webfont.svg#work_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}