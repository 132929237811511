.form-rodape{
	max-width:430px;
	margin-right:auto;

	form{

		.input-group,
		.form-group{
			margin-bottom:5px;
			position:relative;

			.form-control{
				font-size:14px;
				@include sombra(#FFF);
				@include place(#3a3a3a);
				font-family: 'work_sansregular';
				min-height:35px;
				border-radius:30px;
				padding-left:24px;
				border-color:#FFF;
			}

			textarea.form-control{
				resize:none;
				padding-top:11px;
				min-height:92px;
				padding-left:22px;
				border-radius:20px;
			}

			.input-group-prepend{
				position:absolute;
				bottom:5px;
				right:6px;
				z-index:10;

				.btn{
					border-radius:100%;
					min-width:38px;
					padding:0;
					min-height:38px;
					display:inline-flex;
					align-items:center;
					justify-content:center;
				}

			}

		}

	}

}

.form-sem-label{

	form{

		.input-group,
		.form-group{

			label{
				@include sr-only();
			}

		}

	}

}

.form-control-bg-DCDCDC{
	width:100%;
	max-width:100%;

	form{

		.input-group,
		.form-group{

			.form-control{
				background-color:#DCDCDC;
				@include sombra(#DCDCDC);
				border-color:#DCDCDC;
			}

		}

	}

}

.form-depoimentos{

	form{

		.input-group,
		.form-group{
			margin-bottom:7px;

			.form-control{
				resize:none;
				background-color:#DCDCDC;
				border-color:#DCDCDC;
				border-radius:30px;
				padding-left:24px;
				@include place(#3a3a3a);
				@include sombra(#DCDCDC);
				min-height:45px;
				font-size:13px;
			}

			textarea.form-control{
				padding-top:14px;
				border-radius:22px;
			}

		}

	}

}