.btn{

	&.btn--responsivo{
		box-shadow:none;

		.bars{
			width:32px;
			height:5px;
			position:relative;
			display:inline-block;
			background-color:$theme2;

			&,
			&:before,
			&:after{
				transition:all 0.6s linear
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}


			@include beforeAfter{
				content:"";
				display:block;
				width:100%;
				position:absolute;
				height:100%;
				background-color:inherit;
				left:0;
			}

		}

	}

	&.btn--radius-30{
		border-radius:30px;
	}

	&.btn--pill{
		border-radius: 2rem;
	}

	&.btn--block{
		width:100%;
		display:block;
	}

	&.btn--002250-to-019549{
		@include button-variant(#002250,#002250);
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten(#019549,5%),lighten(#019549,5%));
			color:#FFF;
		}

	}

	&.btn--017f40{
		@include button-variant(#017f40,#017f40);
		color:#FFF;
	}

	&.btn--0d2751{
		@include button-variant(#0d2751,#0d2751);
		color:#FFF;
	}

	&.btn--002250-to-00974A{
		@include button-variant(#002250,#002250);
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten(#00974A,5%),lighten(#00974A,5%));
			color:#FFF;
		}

	}

	&.btn--00934B-to-012653{
		@include button-variant(#00934B,#00934B);
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten(#012653,5%),lighten(#012653,5%));
			color:#FFF;
		}

	}

	&.btn--padrao{
		font-size:14px;
		font-family: 'montserratbold';
		border-radius:30px;
		min-width:213px;
		padding:17px 20px;
	}

	&.btn--padrao-peq{
		min-width:113px;
		font-family: 'montserratbold';
		font-size:13px;
		padding:7px 12px;
		border-radius:30px;
	}

	&.btn--padrao-form-depoimentos{
		font-size:14px;
		font-family: 'montserratbold';
		border-radius:30px;
		padding:9px 12px;
	}

	&.btn--badge{
		display:inline-flex;
		align-items:center;
		justify-content:space-between;
		min-width:138px;
		padding:3px 4px 3px 22px;

		.badge{
			font-size:28px;
			width:1em;
			height:1em;
			display:inline-flex;
			align-items:center;
			justify-content:center;
			background-color:#FFF;
			padding:0;
			border-radius:100%;
			margin-left:12px;
		}

	}

	&.btn--112d58-to-00974A{
		@include button-variant(lighten(#112d58,4%),lighten(#112d58,4%));
		color:#FFF;

		@include hover-focus{
			@include button-variant(lighten(#00974A,5%),lighten(#00974A,5%));
			color:#FFF;
		}

	}

	&.btn--min-width-180{
		min-width:180px;
	}

}